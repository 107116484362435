<script setup lang="ts">
import { useBandSignupStore } from '~/stores/bandSignup'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import type { CtaFragment } from '~/graphql/generated'

type Props = CtaFragment

type Emits = {
  close: []
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  type: 'primary',
  showSignupModal: false,
  externalUrl: '',
})
const emit = defineEmits<Emits>()

const route = useRoute()
const { SET_DISPLAY: BAND_SIGNUP_SET_DISPLAY } = useBandSignupStore()

function handleSignupClick() {
  BAND_SIGNUP_SET_DISPLAY(true)
  emit('close')
  trackEvent(
    {
      category: 'Landing',
      action: 'Open sign up dialog',
      label: 'Introduction / CTA',
    },
    route,
  )
}

const buttonClasses = computed(() => [
  props.ctaType === 'primary'
    ? 'tw-bg-[#EB6345] tw-text-white hover:tw-bg-fill-primary-hover hover:tw-shadow-md'
    : 'tw-border tw-border-[#EB6345] tw-text-primary hover:tw-bg-fill-hover-primary hover:tw-shadow-md',
])
</script>

<template>
  <button
    v-if="showSignupModal"
    class="tw-text-body tw-w-full tw-rounded tw-p-lg tw-text-center tw-transition-all tw-duration-100 tw-ease-linear focus:tw-outline focus:tw-outline-2 focus:tw-outline-offset-2 md:tw-w-auto"
    :class="buttonClasses"
    type="button"
    @click="handleSignupClick"
  >
    {{ text }}
  </button>
  <NuxtLink
    v-else-if="externalUrl"
    :to="externalUrl"
    target="_blank"
    class="tw-flex"
  >
    <span
      class="tw-text-body tw-w-full tw-rounded tw-p-lg tw-text-center tw-transition-all tw-duration-100 tw-ease-linear focus:tw-outline focus:tw-outline-2 focus:tw-outline-offset-2 md:tw-w-auto"
      :class="buttonClasses"
    >
      {{ text }}
    </span>
  </NuxtLink>
</template>
