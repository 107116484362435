<script lang="ts" setup>
// Rename file to LanguageSwitcher.vue once you've replaced the old one in all cases.
// This should replace the ~/components/nav/switchLang.vue component too.
import {
  UIDropdownMenu,
  UIDropdownMenuList,
  UIDropdownMenuListItem,
  UIIcon,
} from '@groover-dev/groover-ui'
import { storeToRefs } from 'pinia'

import { useLanguage } from '~/composables/useLanguage'

import { useUserStore } from '~/stores/user'

import { availableLanguages } from '~/types/language'

import type { StringMultiLocaleField } from '~/graphql/generated'
import type { Language } from '~/types/language'

type Props = {
  localeVerbosity?: 'full-length' | 'short'
  landingPageAvailableLocales?: StringMultiLocaleField[]
}
type Emits = {
  'switch-language': []
}

const props = withDefaults(defineProps<Props>(), {
  localeVerbosity: 'short',
  landingPageAvailableLocales: () => [],
})
const emit = defineEmits<Emits>()

const localePath = useLocalePath()

const { email: USER_EMAIL } = storeToRefs(useUserStore())

const { getCurrentLanguage, setCurrentLanguage } = useLanguage()

const currentLanguage = computed(() => getCurrentLanguage())
const sortedAvailableLanguageChoices = computed(() =>
  availableLanguages.toSorted(),
)

async function switchToLanguage(language: Language) {
  emit('switch-language')

  // if the landing page has the locale, navigate to it
  // NOTE: this is isn't the deepest check since the language passed in includes the country code
  // but the landing page slug locales currently only have two character language codes
  if (
    props.landingPageAvailableLocales?.length &&
    props.landingPageAvailableLocales.find((pageLocale) =>
      language.includes(pageLocale.locale || ''),
    )
  )
    return navigateTo(await setCurrentLanguage(language, USER_EMAIL.value))

  // otherwise, navigate to the groover.co page (for now)
  await setCurrentLanguage(language, USER_EMAIL.value)
  return navigateTo(localePath('/'))
}
</script>

<template>
  <UIDropdownMenu
    reference-component="button"
    :reference-component-attrs="{
      type: 'button',
      class: 'tw-flex tw-items-center tw-w-full tw-cursor-pointer tw-gap-x-sm',
    }"
    :hide-delay="250"
  >
    <template #referenceText
      ><UIIcon
        name="material-symbols:language"
        class="tw-text-icon-discrete2"
      /><span class="tw-text-body-sm">{{
        $t(
          `${localeVerbosity === 'full-length' ? 'locales' : 'localesShort'}.${currentLanguage}`,
        )
      }}</span></template
    >
    <template #menuLists>
      <UIDropdownMenuList>
        <UIDropdownMenuListItem
          v-for="language in sortedAvailableLanguageChoices"
          :key="language"
          class="uppercase"
          :text="
            $t(
              `${localeVerbosity === 'full-length' ? 'locales' : 'localesShort'}.${language}`,
            )
          "
          right-icon-name="mdi:check"
          :is-active="language === currentLanguage"
          @click="switchToLanguage(language)"
        />
      </UIDropdownMenuList>
    </template>
  </UIDropdownMenu>
</template>

<style scoped lang="scss">
.uppercase:deep(> button) {
  @apply tw-uppercase;
}
</style>
