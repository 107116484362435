<script setup lang="ts">
import {
  IsPageVisibleForLoggedInUsersDocument,
  LandingPageDocument,
} from '~/graphql/generated'
import { getActivePinia } from 'pinia'
import { toHead } from 'vue-datocms'

import Box from '~/components/dato/Box.vue'
import Hero from '~/components/dato/Hero.vue'
import Navbar from '~/components/dato/Navbar/Navbar.vue'
import PageFooter from '~/components/shared/footer/index.vue'

import { useGraphqlQuery } from '~/composables/useGraphqlQuery'

import { useLoginStore } from '~/stores/login'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useUserStore } from '~/stores/user'

import type {
  BoxFragment,
  CommonContentRecord,
  ReusableContentBoxFragment,
} from '~/graphql/generated'
import type { ToMetaTagsType } from 'vue-datocms'

const route = useRoute()
const { locale } = useI18n()

const { queryDatoCms } = useGraphqlQuery()

const { display: LOGIN_IS_DISPLAYED } = storeToRefs(useLoginStore())
const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { SETUP_EVENT_LISTENERS: SETUP_RESIZE_EVENT_LISTENERS } =
  useMiscResizeStore()

definePageMeta({
  async middleware(to) {
    const { IS_LOGGED_IN } = useUserStore(getActivePinia())
    const { $localePath } = useNuxtApp()
    const { queryDatoCms } = useGraphqlQuery()

    const response = await queryDatoCms(IsPageVisibleForLoggedInUsersDocument, {
      slug: to.params.slug,
    }).catch(console.error)

    if (!response?.data.value?.landingPage)
      throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

    const isAvailableForLoggedInUsers =
      response.data.value.landingPage.isPageVisibleForLoggedInUsers

    if (isAvailableForLoggedInUsers || !IS_LOGGED_IN) return

    return navigateTo($localePath('/'))
  },
  layout: false,
})

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})

onMounted(() => {
  SETUP_RESIZE_EVENT_LISTENERS()
})

const { data } = await useAsyncData(async () => {
  const response = await queryDatoCms(LandingPageDocument, {
    slug: route.params.slug || 'main-lp-groover-co',
    locale: locale.value,
  }).catch(console.error)

  if (!response?.data.value?.landingPage)
    throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

  return {
    landingPage: response.data.value.landingPage,
    commonContent: response.data.value.commonContent,
  }
})

if (data.value === null)
  throw createError({ statusCode: 404, statusMessage: 'Page Not Found' })

const { landingPage, commonContent } = data.value

useHead(() => {
  if (!landingPage?.seo) return {}

  const seoMetaTags = toHead(landingPage.seo as ToMetaTagsType)

  return {
    ...seoMetaTags,
    meta: [
      {
        key: 'robots',
        name: 'robots',
        content: route.params.slug ? 'noindex, nofollow' : 'index, follow',
      },
      ...(seoMetaTags.meta || []),
    ],
    htmlAttrs: {
      class: 'tw-scroll-smooth',
    },
  }
})

const boxRecordSections = computed(() =>
  landingPage.sections.reduce<BoxFragment[]>((accumulator, section) => {
    if (sectionIsBoxRecord(section)) accumulator.push(section)
    else if (
      sectionIsReusableBoxRecord(section) &&
      section.reusableBox?.box?.sections
    )
      accumulator.push(section.reusableBox?.box)
    return accumulator
  }, []),
)

function sectionIsBoxRecord(
  section: BoxFragment | ReusableContentBoxFragment,
): section is BoxFragment {
  return section.__typename === 'BoxRecord'
}

function sectionIsReusableBoxRecord(
  section: BoxFragment | ReusableContentBoxFragment,
): section is ReusableContentBoxFragment {
  return section.__typename === 'ReusableBoxRecord'
}
</script>

<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <div v-if="landingPage">
      <Navbar
        v-if="landingPage.navbar?.navbar?.navbar"
        v-bind="landingPage.navbar?.navbar?.navbar"
        :landing-page-available-locales="[...landingPage.slugLocales]"
      />
      <div
        v-if="landingPage.hero"
        class="tw-bg-surface tw-pb-2xl tw-pt-4xl md:tw-pb-3xl md:tw-pt-5xl lg:tw-pb-4xl lg:tw-pt-7xl"
      >
        <Hero v-bind="landingPage.hero" />
      </div>
      <div
        v-for="(box, index) in boxRecordSections"
        :id="`box-${index + 1}`"
        :key="box.id"
      >
        <Box
          v-bind="{
            ...box,
            commonContent: commonContent as CommonContentRecord,
          }"
        />
      </div>
      <PageFooter />
      <transition>
        <LazyAuthLogin
          v-if="LOGIN_IS_DISPLAYED"
          :model-value="LOGIN_IS_DISPLAYED"
          class="inLayoutIgnoreFontUpscale ignoreFontUpscale"
          @update:model-value="LOGIN_SET_DISPLAY"
        />
      </transition>
      <LazyBandSignupInit class="inLayoutIgnoreFontUpscale ignoreFontUpscale" />
    </div>
  </Html>
</template>

<style scoped lang="scss">
:deep(.mainParentContainer) {
  @apply tw-max-w-[1200px] tw-px-lg md:tw-px-xl min-[1280px]:tw-mx-auto min-[1280px]:tw-px-0;
}
</style>
